// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import classNames from 'classnames/bind';
import React, { useEffect, useState,useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import LoadingSpinner from './LoadingSpinner';
import styles from './ScreenPicker.module.css';

const cx = classNames.bind(styles);

enum ShareType {
  Screen,
  Window
}

type Props = {
  onClickShareButton: (selectedSourceId: string) => void;
  onClickCancelButton: () => void;
};

export default function ScreenPicker(props: Props) {
  const { onClickCancelButton, onClickShareButton } = props;
  const [sources, setSources] = useState<null>(null);
  const [shareType, setShareType] = useState(ShareType.Window);
  const [selectedSourceId, setSelectedSourceId] = useState<string | null>(null);
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  
 

  useEffect(() => {
    const start = async () => {
      try {
        await chime?.startContentShare()
      

      //TODO: REVIEW this part from original code and try to return it back  
      /*
        chime?.audioVideo?.addObserver({
          audioVideoDidStop: (sessionStatus: MeetingSessionStatus): void => {
            if (
              sessionStatus.statusCode() ===
              MeetingSessionStatusCode.AudioCallEnded
            ) {
              history.push('/');
              chime?.leaveRoom(state.classMode === ClassMode.Teacher);
            }
          }
        });

        */
      } catch (error) {
        console.log("error")
      }
    };
    start();
  }, []);
  

  return (
    <div >
      
    </div>
  );
}
